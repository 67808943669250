/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * z-career API for talent
 * z-career API for talent web applications.
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import type {
  BulkKeepJobBody,
  CreateJobViewLogs201,
  DettachKeptJobsBody,
  FetchJobsByIdsParams,
  GetJob200,
  GetJobListParams,
  GetKeptJobsParams,
  JobList,
  ValidationError,
  ViewLogsRequest,
} from '../zCareerAPIForTalent.schemas'
import { axiosInstance } from '../../../axios'
import type { ErrorType, BodyType } from '../../../axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 求人一覧を取得するAPI
 */
export const getJobList = (
  params?: GetJobListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<JobList>({ url: `/talent/job`, method: 'GET', params, signal }, options)
}

export const getGetJobListQueryKey = (params?: GetJobListParams) => {
  return [`/talent/job`, ...(params ? [params] : [])] as const
}

export const getGetJobListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobList>>> = ({ signal }) =>
    getJobList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getJobList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getJobList>>>
export type GetJobListInfiniteQueryError = ErrorType<void | ValidationError>

/**
 * @summary 求人一覧を取得するAPI
 */

export function useGetJobListInfinite<
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 求人一覧を取得するAPI
 */
export const prefetchGetJobListInfinite = async <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  queryClient: QueryClient,
  params?: GetJobListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): Promise<QueryClient> => {
  const queryOptions = getGetJobListInfiniteQueryOptions(params, options)

  await queryClient.prefetchInfiniteQuery(queryOptions)

  return queryClient
}

export const getGetJobListQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobList>>> = ({ signal }) =>
    getJobList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobListQueryResult = NonNullable<Awaited<ReturnType<typeof getJobList>>>
export type GetJobListQueryError = ErrorType<void | ValidationError>

/**
 * @summary 求人一覧を取得するAPI
 */

export function useGetJobList<
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 求人一覧を取得するAPI
 */
export const prefetchGetJobList = async <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  queryClient: QueryClient,
  params?: GetJobListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): Promise<QueryClient> => {
  const queryOptions = getGetJobListQueryOptions(params, options)

  await queryClient.prefetchQuery(queryOptions)

  return queryClient
}

export const getGetJobListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobList>>> = ({ signal }) =>
    getJobList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getJobList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobListSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getJobList>>>
export type GetJobListSuspenseQueryError = ErrorType<void | ValidationError>

/**
 * @summary 求人一覧を取得するAPI
 */

export function useGetJobListSuspense<
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<void | ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobListSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 求人を単体取得するAPI
 */
export const getJob = (
  jobId: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GetJob200>(
    { url: `/talent/job/${encodeURIComponent(String(jobId))}`, method: 'GET', signal },
    options,
  )
}

export const getGetJobQueryKey = (jobId: string) => {
  return [`/talent/job/${jobId}`] as const
}

export const getGetJobQueryOptions = <
  TData = Awaited<ReturnType<typeof getJob>>,
  TError = ErrorType<void>,
>(
  jobId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobQueryKey(jobId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJob>>> = ({ signal }) =>
    getJob(jobId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJob>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobQueryResult = NonNullable<Awaited<ReturnType<typeof getJob>>>
export type GetJobQueryError = ErrorType<void>

/**
 * @summary 求人を単体取得するAPI
 */

export function useGetJob<TData = Awaited<ReturnType<typeof getJob>>, TError = ErrorType<void>>(
  jobId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobQueryOptions(jobId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetJobSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getJob>>,
  TError = ErrorType<void>,
>(
  jobId: string,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobQueryKey(jobId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJob>>> = ({ signal }) =>
    getJob(jobId, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getJob>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getJob>>>
export type GetJobSuspenseQueryError = ErrorType<void>

/**
 * @summary 求人を単体取得するAPI
 */

export function useGetJobSuspense<
  TData = Awaited<ReturnType<typeof getJob>>,
  TError = ErrorType<void>,
>(
  jobId: string,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobSuspenseQueryOptions(jobId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary お気に入り求人一覧を取得するAPI
 */
export const getKeptJobs = (
  params?: GetKeptJobsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<JobList>({ url: `/talent/job/keep`, method: 'GET', params, signal }, options)
}

export const getGetKeptJobsQueryKey = (params?: GetKeptJobsParams) => {
  return [`/talent/job/keep`, ...(params ? [params] : [])] as const
}

export const getGetKeptJobsQueryOptions = <
  TData = Awaited<ReturnType<typeof getKeptJobs>>,
  TError = ErrorType<void>,
>(
  params?: GetKeptJobsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getKeptJobs>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetKeptJobsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getKeptJobs>>> = ({ signal }) =>
    getKeptJobs(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKeptJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetKeptJobsQueryResult = NonNullable<Awaited<ReturnType<typeof getKeptJobs>>>
export type GetKeptJobsQueryError = ErrorType<void>

/**
 * @summary お気に入り求人一覧を取得するAPI
 */

export function useGetKeptJobs<
  TData = Awaited<ReturnType<typeof getKeptJobs>>,
  TError = ErrorType<void>,
>(
  params?: GetKeptJobsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getKeptJobs>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetKeptJobsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetKeptJobsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getKeptJobs>>,
  TError = ErrorType<void>,
>(
  params?: GetKeptJobsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKeptJobs>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetKeptJobsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getKeptJobs>>> = ({ signal }) =>
    getKeptJobs(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getKeptJobs>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetKeptJobsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getKeptJobs>>>
export type GetKeptJobsSuspenseQueryError = ErrorType<void>

/**
 * @summary お気に入り求人一覧を取得するAPI
 */

export function useGetKeptJobsSuspense<
  TData = Awaited<ReturnType<typeof getKeptJobs>>,
  TError = ErrorType<void>,
>(
  params?: GetKeptJobsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getKeptJobs>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetKeptJobsSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary お気に入り求人解除API
 */
export const dettachKeptJobs = (
  dettachKeptJobsBody: BodyType<DettachKeptJobsBody>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    {
      url: `/talent/job/keep/dettach`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: dettachKeptJobsBody,
      signal,
    },
    options,
  )
}

export const getDettachKeptJobsMutationOptions = <
  TData = Awaited<ReturnType<typeof dettachKeptJobs>>,
  TError = ErrorType<void | ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<DettachKeptJobsBody> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['dettachKeptJobs']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dettachKeptJobs>>,
    { data: BodyType<DettachKeptJobsBody> }
  > = (props) => {
    const { data } = props ?? {}

    return dettachKeptJobs(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<DettachKeptJobsBody> },
    TContext
  >
}

export type DettachKeptJobsMutationResult = NonNullable<Awaited<ReturnType<typeof dettachKeptJobs>>>
export type DettachKeptJobsMutationBody = BodyType<DettachKeptJobsBody>
export type DettachKeptJobsMutationError = ErrorType<void | ValidationError>

/**
 * @summary お気に入り求人解除API
 */
export const useDettachKeptJobs = <
  TData = Awaited<ReturnType<typeof dettachKeptJobs>>,
  TError = ErrorType<void | ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<DettachKeptJobsBody> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<DettachKeptJobsBody> }, TContext> => {
  const mutationOptions = getDettachKeptJobsMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary お気に入り求人一括登録API
 */
export const bulkKeepJob = (
  bulkKeepJobBody: BodyType<BulkKeepJobBody>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<void>(
    {
      url: `/talent/job/keep/bulk`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bulkKeepJobBody,
      signal,
    },
    options,
  )
}

export const getBulkKeepJobMutationOptions = <
  TData = Awaited<ReturnType<typeof bulkKeepJob>>,
  TError = ErrorType<void | ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<BulkKeepJobBody> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['bulkKeepJob']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkKeepJob>>,
    { data: BodyType<BulkKeepJobBody> }
  > = (props) => {
    const { data } = props ?? {}

    return bulkKeepJob(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<BulkKeepJobBody> },
    TContext
  >
}

export type BulkKeepJobMutationResult = NonNullable<Awaited<ReturnType<typeof bulkKeepJob>>>
export type BulkKeepJobMutationBody = BodyType<BulkKeepJobBody>
export type BulkKeepJobMutationError = ErrorType<void | ValidationError>

/**
 * @summary お気に入り求人一括登録API
 */
export const useBulkKeepJob = <
  TData = Awaited<ReturnType<typeof bulkKeepJob>>,
  TError = ErrorType<void | ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<BulkKeepJobBody> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<BulkKeepJobBody> }, TContext> => {
  const mutationOptions = getBulkKeepJobMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary 求人をID指定で一括取得するAPI
 */
export const fetchJobsByIds = (
  params: FetchJobsByIdsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<JobList>(
    { url: `/talent/job/fetch`, method: 'GET', params, signal },
    options,
  )
}

export const getFetchJobsByIdsQueryKey = (params: FetchJobsByIdsParams) => {
  return [`/talent/job/fetch`, ...(params ? [params] : [])] as const
}

export const getFetchJobsByIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchJobsByIdsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchJobsByIds>>> = ({ signal }) =>
    fetchJobsByIds(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchJobsByIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchJobsByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchJobsByIds>>>
export type FetchJobsByIdsQueryError = ErrorType<void | ValidationError>

/**
 * @summary 求人をID指定で一括取得するAPI
 */

export function useFetchJobsByIds<
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchJobsByIdsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFetchJobsByIdsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchJobsByIdsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchJobsByIds>>> = ({ signal }) =>
    fetchJobsByIds(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof fetchJobsByIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchJobsByIdsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchJobsByIds>>
>
export type FetchJobsByIdsSuspenseQueryError = ErrorType<void | ValidationError>

/**
 * @summary 求人をID指定で一括取得するAPI
 */

export function useFetchJobsByIdsSuspense<
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<void | ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchJobsByIdsSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 閲覧求人詳細ログ登録API
 */
export const createJobViewLogs = (
  viewLogsRequest: BodyType<ViewLogsRequest>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<CreateJobViewLogs201>(
    {
      url: `/talent/job/view-logs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: viewLogsRequest,
      signal,
    },
    options,
  )
}

export const getCreateJobViewLogsMutationOptions = <
  TData = Awaited<ReturnType<typeof createJobViewLogs>>,
  TError = ErrorType<ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<ViewLogsRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['createJobViewLogs']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createJobViewLogs>>,
    { data: BodyType<ViewLogsRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return createJobViewLogs(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<ViewLogsRequest> },
    TContext
  >
}

export type CreateJobViewLogsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createJobViewLogs>>
>
export type CreateJobViewLogsMutationBody = BodyType<ViewLogsRequest>
export type CreateJobViewLogsMutationError = ErrorType<ValidationError>

/**
 * @summary 閲覧求人詳細ログ登録API
 */
export const useCreateJobViewLogs = <
  TData = Awaited<ReturnType<typeof createJobViewLogs>>,
  TError = ErrorType<ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<ViewLogsRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<ViewLogsRequest> }, TContext> => {
  const mutationOptions = getCreateJobViewLogsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * z-career Public API
 * z-career Public API.
 * OpenAPI spec version: 1.0.0
 */
import { useInfiniteQuery, useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query'
import type {
  Count,
  FetchJobsByIdsParams,
  GetJob200,
  GetJobCountParams,
  GetJobListParams,
  JobLink,
  JobList,
  SearchLog,
  SearchLogsRequest,
  ValidationError,
} from '../zCareerPublicAPI.schemas'
import { axiosInstance } from '../../../axios'
import type { ErrorType, BodyType } from '../../../axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary 求人一覧を取得するAPI
 */
export const getJobList = (
  params?: GetJobListParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<JobList>({ url: `/public/job`, method: 'GET', params, signal }, options)
}

export const getGetJobListQueryKey = (params?: GetJobListParams) => {
  return [`/public/job`, ...(params ? [params] : [])] as const
}

export const getGetJobListInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobList>>> = ({ signal }) =>
    getJobList(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getJobList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getJobList>>>
export type GetJobListInfiniteQueryError = ErrorType<ValidationError>

/**
 * @summary 求人一覧を取得するAPI
 */

export function useGetJobListInfinite<
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<ValidationError>,
>(
  params?: GetJobListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobListInfiniteQueryOptions(params, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 求人一覧を取得するAPI
 */
export const prefetchGetJobListInfinite = async <
  TData = Awaited<ReturnType<typeof getJobList>>,
  TError = ErrorType<ValidationError>,
>(
  queryClient: QueryClient,
  params?: GetJobListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof getJobList>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): Promise<QueryClient> => {
  const queryOptions = getGetJobListInfiniteQueryOptions(params, options)

  await queryClient.prefetchInfiniteQuery(queryOptions)

  return queryClient
}

/**
 * @summary 求人を単体取得するAPI
 */
export const getJob = (
  jobId: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<GetJob200>(
    { url: `/public/job/${encodeURIComponent(String(jobId))}`, method: 'GET', signal },
    options,
  )
}

export const getGetJobQueryKey = (jobId: string) => {
  return [`/public/job/${jobId}`] as const
}

export const getGetJobQueryOptions = <
  TData = Awaited<ReturnType<typeof getJob>>,
  TError = ErrorType<unknown>,
>(
  jobId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobQueryKey(jobId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJob>>> = ({ signal }) =>
    getJob(jobId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!jobId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJob>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobQueryResult = NonNullable<Awaited<ReturnType<typeof getJob>>>
export type GetJobQueryError = ErrorType<unknown>

/**
 * @summary 求人を単体取得するAPI
 */

export function useGetJob<TData = Awaited<ReturnType<typeof getJob>>, TError = ErrorType<unknown>>(
  jobId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobQueryOptions(jobId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetJobSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getJob>>,
  TError = ErrorType<unknown>,
>(
  jobId: string,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobQueryKey(jobId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJob>>> = ({ signal }) =>
    getJob(jobId, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getJob>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getJob>>>
export type GetJobSuspenseQueryError = ErrorType<unknown>

/**
 * @summary 求人を単体取得するAPI
 */

export function useGetJobSuspense<
  TData = Awaited<ReturnType<typeof getJob>>,
  TError = ErrorType<unknown>,
>(
  jobId: string,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJob>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobSuspenseQueryOptions(jobId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 求人をID指定で一括取得するAPI
 */
export const fetchJobsByIds = (
  params: FetchJobsByIdsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<JobList>(
    { url: `/public/job/fetch`, method: 'GET', params, signal },
    options,
  )
}

export const getFetchJobsByIdsQueryKey = (params: FetchJobsByIdsParams) => {
  return [`/public/job/fetch`, ...(params ? [params] : [])] as const
}

export const getFetchJobsByIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchJobsByIdsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchJobsByIds>>> = ({ signal }) =>
    fetchJobsByIds(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchJobsByIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchJobsByIdsQueryResult = NonNullable<Awaited<ReturnType<typeof fetchJobsByIds>>>
export type FetchJobsByIdsQueryError = ErrorType<ValidationError>

/**
 * @summary 求人をID指定で一括取得するAPI
 */

export function useFetchJobsByIds<
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchJobsByIdsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFetchJobsByIdsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFetchJobsByIdsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchJobsByIds>>> = ({ signal }) =>
    fetchJobsByIds(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof fetchJobsByIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FetchJobsByIdsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchJobsByIds>>
>
export type FetchJobsByIdsSuspenseQueryError = ErrorType<ValidationError>

/**
 * @summary 求人をID指定で一括取得するAPI
 */

export function useFetchJobsByIdsSuspense<
  TData = Awaited<ReturnType<typeof fetchJobsByIds>>,
  TError = ErrorType<ValidationError>,
>(
  params: FetchJobsByIdsParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof fetchJobsByIds>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getFetchJobsByIdsSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 検索にHitする求人数を取得するAPI
 */
export const getJobCount = (
  params?: GetJobCountParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<Count>({ url: `/public/job/count`, method: 'GET', params, signal }, options)
}

export const getGetJobCountQueryKey = (params?: GetJobCountParams) => {
  return [`/public/job/count`, ...(params ? [params] : [])] as const
}

export const getGetJobCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobCount>>,
  TError = ErrorType<unknown>,
>(
  params?: GetJobCountParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobCount>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobCountQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobCount>>> = ({ signal }) =>
    getJobCount(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobCount>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobCountQueryResult = NonNullable<Awaited<ReturnType<typeof getJobCount>>>
export type GetJobCountQueryError = ErrorType<unknown>

/**
 * @summary 検索にHitする求人数を取得するAPI
 */

export function useGetJobCount<
  TData = Awaited<ReturnType<typeof getJobCount>>,
  TError = ErrorType<unknown>,
>(
  params?: GetJobCountParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobCount>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobCountQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetJobCountSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobCount>>,
  TError = ErrorType<unknown>,
>(
  params?: GetJobCountParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJobCount>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobCountQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobCount>>> = ({ signal }) =>
    getJobCount(params, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getJobCount>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobCountSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getJobCount>>>
export type GetJobCountSuspenseQueryError = ErrorType<unknown>

/**
 * @summary 検索にHitする求人数を取得するAPI
 */

export function useGetJobCountSuspense<
  TData = Awaited<ReturnType<typeof getJobCount>>,
  TError = ErrorType<unknown>,
>(
  params?: GetJobCountParams,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJobCount>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobCountSuspenseQueryOptions(params, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary 企業IDで関連求人情報を取得するAPI
 */
export const getJobCompanyRelated = (
  companyId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<JobLink[]>(
    {
      url: `/public/job/related/company/${encodeURIComponent(String(companyId))}`,
      method: 'GET',
      signal,
    },
    options,
  )
}

export const getGetJobCompanyRelatedQueryKey = (companyId: number) => {
  return [`/public/job/related/company/${companyId}`] as const
}

export const getGetJobCompanyRelatedQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobCompanyRelated>>,
  TError = ErrorType<void>,
>(
  companyId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobCompanyRelated>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobCompanyRelatedQueryKey(companyId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobCompanyRelated>>> = ({ signal }) =>
    getJobCompanyRelated(companyId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!companyId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobCompanyRelated>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobCompanyRelatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobCompanyRelated>>
>
export type GetJobCompanyRelatedQueryError = ErrorType<void>

/**
 * @summary 企業IDで関連求人情報を取得するAPI
 */

export function useGetJobCompanyRelated<
  TData = Awaited<ReturnType<typeof getJobCompanyRelated>>,
  TError = ErrorType<void>,
>(
  companyId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getJobCompanyRelated>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobCompanyRelatedQueryOptions(companyId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetJobCompanyRelatedSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobCompanyRelated>>,
  TError = ErrorType<void>,
>(
  companyId: number,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJobCompanyRelated>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetJobCompanyRelatedQueryKey(companyId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobCompanyRelated>>> = ({ signal }) =>
    getJobCompanyRelated(companyId, requestOptions, signal)

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getJobCompanyRelated>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetJobCompanyRelatedSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobCompanyRelated>>
>
export type GetJobCompanyRelatedSuspenseQueryError = ErrorType<void>

/**
 * @summary 企業IDで関連求人情報を取得するAPI
 */

export function useGetJobCompanyRelatedSuspense<
  TData = Awaited<ReturnType<typeof getJobCompanyRelated>>,
  TError = ErrorType<void>,
>(
  companyId: number,
  options?: {
    query?: UseSuspenseQueryOptions<Awaited<ReturnType<typeof getJobCompanyRelated>>, TError, TData>
    request?: SecondParameter<typeof axiosInstance>
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobCompanyRelatedSuspenseQueryOptions(companyId, options)

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * 求人検索ログを登録する
 * @summary 求人検索ログ登録
 */
export const createJobSearchLogs = (
  searchLogsRequest: BodyType<SearchLogsRequest>,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal,
) => {
  return axiosInstance<SearchLog>(
    {
      url: `/public/analytics/search-logs`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchLogsRequest,
      signal,
    },
    options,
  )
}

export const getCreateJobSearchLogsMutationOptions = <
  TData = Awaited<ReturnType<typeof createJobSearchLogs>>,
  TError = ErrorType<ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<SearchLogsRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}) => {
  const mutationKey = ['createJobSearchLogs']
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined }

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createJobSearchLogs>>,
    { data: BodyType<SearchLogsRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return createJobSearchLogs(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { data: BodyType<SearchLogsRequest> },
    TContext
  >
}

export type CreateJobSearchLogsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createJobSearchLogs>>
>
export type CreateJobSearchLogsMutationBody = BodyType<SearchLogsRequest>
export type CreateJobSearchLogsMutationError = ErrorType<ValidationError>

/**
 * @summary 求人検索ログ登録
 */
export const useCreateJobSearchLogs = <
  TData = Awaited<ReturnType<typeof createJobSearchLogs>>,
  TError = ErrorType<ValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, { data: BodyType<SearchLogsRequest> }, TContext>
  request?: SecondParameter<typeof axiosInstance>
}): UseMutationResult<TData, TError, { data: BodyType<SearchLogsRequest> }, TContext> => {
  const mutationOptions = getCreateJobSearchLogsMutationOptions(options)

  return useMutation(mutationOptions)
}
